import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import { StoreState } from '../../store';
import { ThunkDispatch } from '../../store/types';

interface StatePropsFromStore {
    active: boolean;
}

interface DispatchProps {
    cancelProjectRemoval: () => void;
    confirmProjectRemoval: () => void;
}

type Props = DispatchProps & StatePropsFromStore;

class RemoveProjectModal extends React.PureComponent<Props, {}> {

    public render() {
        const {
            active, cancelProjectRemoval, confirmProjectRemoval
        } = this.props;

        return (
            <Modal
                open={active}
                onClose={cancelProjectRemoval}
                basic={true}
                size="tiny"
                className={'modal-fix'}
            >
                <Header icon="trash" content="Delete project?" />
                <Modal.Content>
                    <p>
                        Do you really want to delete this project? The action is permanent!
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        basic={true}
                        color="red"
                        inverted={true}
                        onClick={cancelProjectRemoval}
                    >
                        <Icon name="remove" /> No
                    </Button>
                    <Button color="green" inverted={true} onClick={confirmProjectRemoval}>
                        <Icon name="checkmark" /> Yes
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

function mapStateToProps(state: StoreState): StatePropsFromStore {
    return {
        active: false,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        cancelProjectRemoval: () => {
        },
        confirmProjectRemoval: () => {
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoveProjectModal);