import { TargetCollection, TaskStatusUpdate, UsersRolesChangeEvent } from '../proto/usersroles_pb';
import { ChangeEventStore } from '../store/types';
import composable from './composable';

export interface TaskStatusUpdatesStore extends ChangeEventStore<TaskStatusUpdate> {}

export default composable<TaskStatusUpdate>(
    TargetCollection.TASKSTATUSUPDATES,
    UsersRolesChangeEvent.prototype.getTaskstatusupdate
);
