import { push } from 'connected-react-router';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Confirm, Dropdown } from 'semantic-ui-react';
import { deleteProject } from '../../actions/projects';
import { P_TRIAL_ENABLED } from '../../utils/constants';
import ImportFileModal from './ImportFileModal';

interface ProjectDropdownProps {

    projectId: string;
    projectName: string;
}

const ProjectDropdown = ({ projectId, projectName, }: ProjectDropdownProps) => {

    const [fileImportModalOpen, setFileImportModalOpen] = useState(false);
    const [showDeleteProjectDialog, setShowDeleteProjectDialog] = useState(false);

    const dispatch = useDispatch();

    const editProject = () => {
        dispatch(push('/edit/' + projectId));
    };

    const exportProject = () => {
        dispatch(push('/export/' + projectId));
    };

    const dropdownItems = P_TRIAL_ENABLED ? [(
        <>
            <Dropdown.Item
                icon={'edit'}
                text={'Edit..'}
                onClick={editProject}
            />
            <Dropdown.Item
                icon={'file'}
                text="Import file"
                onClick={() => setFileImportModalOpen(true)}
            />
            <Dropdown.Item
                icon={'share'}
                text={'Export'}
                onClick={exportProject}
                // as={'a'}
                // href={constructExportLink(projectId)}
                // target="blank_"
            />
        </>
    )] : [];

    const handleConfirm = () => {
        dispatch(deleteProject(projectId));
        setShowDeleteProjectDialog(false);
    };

    const deleteModal = showDeleteProjectDialog ? (
        <Confirm
            header={'Delete project ' + projectName}
            content={'Are you sure to delete project ' + projectName + '? This action is permanent!'}
            open={showDeleteProjectDialog}
            onCancel={() => setShowDeleteProjectDialog(false)}
            onConfirm={handleConfirm}
        />
    ) : null;

    const importFileModal = fileImportModalOpen ? (
        <ImportFileModal projectId={projectId} open={fileImportModalOpen} modalClosed={() => setFileImportModalOpen(false)} />
    ) : null;

    return (
        <>
            <Dropdown style={{ float: 'right' }} item={true} icon="bars">
                <Dropdown.Menu direction={'left'}>
                    {dropdownItems}
                    <Dropdown.Item
                        icon={'trash'}
                        text={'Delete'}
                        onClick={() => setShowDeleteProjectDialog(true)}
                    />
                </Dropdown.Menu>
            </Dropdown>
            {deleteModal}
            {importFileModal}
        </>
    );
};

export default ProjectDropdown;
