import { LOADING_USER, USER_EXPIRED, USER_FOUND, USER_LOADED, SESSION_TERMINATED } from 'redux-oidc';
import { IStoreActions } from '../actions';

export interface OidcState {
    loadingUser: boolean;
    userLoaded: boolean;
    userFound: boolean;
    userExpired: boolean;
}

const initialState = {
    loadingUser: false,
    userLoaded: false,
    userFound: false,
    userExpired: false,
};

export default function(state: OidcState = initialState, action: IStoreActions): OidcState {
    switch (action.type) {
        case LOADING_USER: {
            return {
                ...state,
                loadingUser: true
            };
        }
        case USER_LOADED: {
            return {
                ...state,
                userLoaded: true
            };
        }
        case USER_FOUND: {
            return {
                ...state,
                userFound: true
            };
        }
        case USER_EXPIRED:
        case SESSION_TERMINATED: {
            return {
                ...state,
                userExpired: true
            };
        }
        default:
            return state;
        }
}
