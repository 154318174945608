import { push } from 'connected-react-router';
import isEqual from 'lodash.isequal';
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, DropdownProps, Form, Icon, Input, Message, Modal } from 'semantic-ui-react';
import { createProject } from '../../actions/projects';
import { Task } from '../../proto/usersroles_pb';
import { StoreState } from '../../store';
import { ThunkDispatch } from '../../store/types';
import { P_TRIAL_ENABLED } from '../../utils/constants';

interface OwnProps {
}

interface StatePropsFromStore {
    available: boolean;

    tasks: Array<[string, Task]>;
}

interface DispatchProps {
    closeModal: () => void;
    doCreateProject: (name: string, description: string, taskIds?: string[]) => void;

}

interface OwnState {
    name: string;
    nameValid: boolean;
    description: string;

    taskIdsList: string[];
}

const initialState = {
    description: '',
    name: '',
    nameValid: true,
    taskIdsList: []
};

type Props = OwnProps & DispatchProps & StatePropsFromStore;

class CreateProjectModal extends React.Component<Props, OwnState> {

    private nameInput: React.RefObject<Input>;

    constructor(props: Props) {
        super(props);
        this.nameInput = React.createRef();
        this.state = initialState;
    }

    public componentDidMount() {
        if (this.nameInput.current) {
            this.nameInput.current.focus();
        }
        const currentTaskIds = this.props.tasks.map(([key, _]) => key);
        if (!isEqual(currentTaskIds, this.state.taskIdsList) && currentTaskIds.length === 1) {
            this.setState({
                ...this.state,
                taskIdsList: currentTaskIds
            });
        }
    }

    public componentDidUpdate(prevProps: Props, prevState: OwnState) {
        const currentTaskIds = this.props.tasks.map(([key, _]) => key);
        const previousTaskIds = prevProps.tasks.map(([key, _]) => key);
        if (!isEqual(currentTaskIds, previousTaskIds) && !isEqual(this.state.taskIdsList, prevState.taskIdsList) && currentTaskIds.length === 1) {
            this.setState({
                ...this.state,
                taskIdsList: currentTaskIds
            });
        }
    }

    public render() {
        const { name, nameValid, description, taskIdsList } = this.state;

        const createProjectIcon = (
            <Icon.Group>
                <Icon name="code branch" />
                <Icon corner={true} name="add" />
            </Icon.Group>
        );
        const { available, tasks } = this.props;
        let projectNameErrorMessage = '';
        if (!nameValid) {
            projectNameErrorMessage = 'Name should be less than 60 characters and conform regex [A-Za-z0-9-_]';
        }
        if (available) {
            projectNameErrorMessage = 'Name is already taken';
        }

        const taskOptions = tasks.filter(([taskId, task]) => true/*taskIdsList.includes(taskId) === false*/).map(([taskId, task]) => {
            return {
                key: taskId,
                value: taskId,
                text: task.getName() + ' [' + task.getAppname() + ']',
            };
        });

        return (
            <Modal
                open={true}
                size={'tiny'}
                onClose={() => this.props.closeModal()}
                closeIcon={true}
                className={'modal-fix'}
            >
                <Modal.Header>
                    {createProjectIcon}
                    &nbsp;Create new project
                </Modal.Header>
                <Modal.Content>
                    <Form onSubmit={() => this.onSubmit()} error={projectNameErrorMessage !== ''}>
                        <Form.Field>
                            <label>Name</label>
                            <Input
                                ref={this.nameInput}
                                error={projectNameErrorMessage !== ''}
                                placeholder={'Project Name'}
                                value={name}
                                onChange={(event) => this.handleNameChange(event)}
                            />
                        </Form.Field>
                        <Message
                            error={true}
                            header="Invalid Project Name"
                            content={projectNameErrorMessage}
                        />
                        <Form.Field>
                            <label>Description</label>
                            <input
                                placeholder={'Short description'}
                                value={description}
                                onChange={(event) => this.handleDescriptionChange(event)}
                            />
                        </Form.Field>
                        { P_TRIAL_ENABLED ? (
                            <Form.Field>
                                <label>Tasks</label>
                                <Dropdown
                                    placeholder="Tasks"
                                    fluid={true}
                                    multiple={true}
                                    search={true}
                                    selection={true}
                                    options={taskOptions}
                                    onChange={this.handleTaskChange}
                                    value={taskIdsList}
                                />
                            </Form.Field>
                            ) : null
                        }
                        <Button type={'submit'} disabled={name.length === 0 || projectNameErrorMessage !== ''}>
                            {createProjectIcon}
                            &nbsp;Create project
                        </Button>
                        <Button basic={true} onClick={() => this.props.closeModal()}>Cancel</Button>
                    </Form>
                </Modal.Content>
            </Modal>
        );
    }

    private handleTaskChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        this.setState({
            ...this.state,
            taskIdsList: data.value as string[],
        });
    }

    private handleNameChange(event: React.FormEvent<HTMLInputElement>) {
        const { value } = event.currentTarget;

        // validate name
        const regex = /^[A-Za-z0-9-_]+$/;

        let nameValid = false;
        if (value.length < 60 && value.match(regex)) {
            nameValid = true;
        }
        this.setState({
            ...this.state,
            name: value,
            nameValid,
        });
        // check if name is not taken
    }

    private handleDescriptionChange(event: React.FormEvent<HTMLInputElement>) {
        this.setState({
            ...this.state,
            description: event.currentTarget.value,
        });
    }

    private onSubmit() {
        const { name, description, taskIdsList } = this.state;
        this.props.doCreateProject(name, description, taskIdsList);
        this.props.closeModal();
        this.setState(initialState);
    }
}

function mapStateToProps(state: StoreState): StatePropsFromStore {
    const { tasks, portalApplications } = state;
    // filter out tasks that applications are not UP and renewed in last 60s
    const availableTasks = Object.entries(tasks).filter(([key, task]) => {
        const appl = Object.values(portalApplications).find((app) => task.getAppname() === app.getName());
        if (appl && appl.getRenewed()) {
            // @ts-ignore
            const renewedMS = appl.getRenewed().toDate().getTime();
            const now = new Date().getTime();
            return appl.getStatus() === 'UP' && (now - renewedMS < 60 * 1000);
        }
        return false;
    });
    return {
        available: false,
        tasks: availableTasks,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        closeModal: () => {
            dispatch(push('/'));
        },
        doCreateProject: (name: string, description: string, taskIds?: string[]) => {
            dispatch(createProject(name, description, taskIds));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateProjectModal);
