import jsLogger from 'js-logger';
import { IContext } from 'js-logger/src/types';

const level = process.env.NODE_ENV === 'production' ? jsLogger.INFO : jsLogger.DEBUG;

jsLogger.useDefaults({
    defaultLevel: level,
    formatter: (messages: string[], context: IContext) => {
        const date = new Date();
        const currentDate = date.toLocaleDateString();
        const currentTime = date.toLocaleTimeString();
        const prefix = context.level.name + ' [' + currentDate + ' ' + currentTime + ']:';
        messages.unshift(prefix);
    }
});

const Logger = jsLogger.get('p2p');

export const isDebug = () => {
    return level === jsLogger.DEBUG;
};

export default Logger;