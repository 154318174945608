import { Log, Logger, UserManagerSettings } from 'oidc-client';
import { createUserManager } from 'redux-oidc';
import { authority, client_id, client_secret } from './constants';

const logger: Logger = {
    error(message?: any, ...optionalParams: any[]): void {
        console.log(message + ' ' + JSON.stringify(optionalParams));
    },
    info(message?: any, ...optionalParams: any[]): void {
        console.log(message + ' ' + JSON.stringify(optionalParams));
    },
    debug(message?: any, ...optionalParams: any[]): void {
        console.log(message + ' ' + JSON.stringify(optionalParams));
    },
    warn(message?: any, ...optionalParams: any[]): void {
        console.log(message + ' ' + JSON.stringify(optionalParams));
    },
};

Log.logger = logger;
Log.level = Log.INFO;

const { protocol, hostname, port } = window.location;

const userManagerConfig: UserManagerSettings = {
  client_id,
  client_secret,
  redirect_uri: `${protocol}//${hostname}${port ? `:${port}` : ''}/callback`,
  response_type: 'code',
  scope: 'openid profile email',
  authority,
  silent_redirect_uri: `${protocol}//${hostname}${port ? `:${port}` : ''}/silent_renew.html`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
