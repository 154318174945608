import { toast } from 'react-semantic-toasts';
import { ChangeOperation, ChangeUserRoleRequest, ChangeUserRoleResponse } from '../proto/usersroles_pb';
import { UsersRolesService } from '../proto/usersroles_pb_service';
import { StoreState } from '../store';
import { ThunkDispatch } from '../store/types';
import { GrpcActionPayload, grpcRequest } from './grpc';

export const changeUserRole = (projectId: string, userId: string, roleId: string, co: (0 | 2)) => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new ChangeUserRoleRequest();
        request.setUserid(userId);
        const oper = co === 0 ? ChangeOperation.INSERT : ChangeOperation.DELETE;
        request.setChangeoperation(oper);
        request.setRoleid(roleId);
        const grpcAction: GrpcActionPayload<ChangeUserRoleRequest, ChangeUserRoleResponse> = {
            methodDescriptor: UsersRolesService.ChangeUserRole,
            request,
            onEnd: () => toast({
                title: co === 0 ? 'Role added' : 'Role removed',
                description: co === 0 ? 'Added ' + roleId + ' for user ' + userId : 'Removed ' + roleId + ' from user ' + userId
            }),
            onError: (code, message) => ({
                title: 'Role modification failed',
                description: 'Failed to modify roles for user ' + userId + ': ' + message,
                type: 'error',
                time: 0,
            })
        };
        dispatch(grpcRequest(grpcAction));
    };
};
