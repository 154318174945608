import * as React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Image } from 'semantic-ui-react';

import gravatar from 'gravatar';

export interface UserDropdownProps {
    username: string;
    email: string;
}

class UserDropdown extends React.Component<UserDropdownProps> {
    public render() {
        const { username, email } = this.props;

        const options = {
            default: 'identicon',
        };
        const avatarUrl = email && email !== '' ? gravatar.url(email, options) : gravatar.url(username, options);

        const trigger = (
            <span>
                <Image avatar={true} src={avatarUrl} /> {username}
            </span>
        );

        return (
            <Dropdown item={true} icon={null} trigger={trigger} >
                <Dropdown.Menu>
                    <Dropdown.Item icon={'help circle'} text={'Help'} as="a" target={'blank_'} href={'/manual/'} />
                    <Dropdown.Item icon={'settings'} text={'Profile'} as={Link} to={'/me'} />
                    <Dropdown.Divider />
                    <Dropdown.Item icon={'info'} text={'About'} as={Link} to={'/about'} />
                    <Dropdown.Item icon={'sign out'} text={'Sign out'} as={Link} to={'/logout'} />
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}

export default UserDropdown;
