import { Project, Role, User } from '../proto/usersroles_pb';
import { ProjectStore } from '../reducers/projects';
import { RoleStore } from '../reducers/roles';
import { StoreState } from '../store';

export const OWNER_ROLE_SUFFIX = '_OWNER_ROLE';
export const MODIFIER_ROLE_SUFFIX = '_MODIFIER_ROLE';
export const VIEWER_ROLE_SUFFIX = '_VIEWER_ROLE';

export const possibleProjectNameFromRole = (role: Role, projects: ProjectStore): string | null => {
    const project = projectFromRole(role, projects);
    if (project) {
        return project.getName();
    }
    return null;
};

const projectFromRole = (role: Role, projects: ProjectStore): Project | null => {
    const rolename = role.getRolename();

    const foundProject = Object.entries(projects).filter(([projectId, _]) => {
        return rolename.startsWith(projectId);
    }).map(([_, project]) => project);

    if (foundProject.length > 0) {
        return foundProject[0];
    }
    return null;
};

export const possibleRoleSuffixLabel = (role: Role): string => {
    if (isOwnerRole(role)) {
        return 'owner';
    } else if (isModifierRole(role)) {
        return 'modifier';
    } else if (isViewerRole(role)) {
        return 'viewer';
    }
    return role.getRolename();
};

const isOwnerRole = (role: Role): boolean => {
    const rolename = role.getRolename();
    return rolename.endsWith(OWNER_ROLE_SUFFIX);
};

const isModifierRole = (role: Role): boolean => {
    const rolename = role.getRolename();
    return rolename.endsWith(MODIFIER_ROLE_SUFFIX);
};

const isViewerRole = (role: Role): boolean => {
    const rolename = role.getRolename();
    return rolename.endsWith(VIEWER_ROLE_SUFFIX);
};

export const isVerifiedUser = (user: User): boolean => {
    const roleIds = user.getVerified();
    return roleIds;
};

export const isAdmin = (user: User, roles: RoleStore): boolean => {
    const roleIds = user.getRoleidsList();
    return roleIds.filter((roleId) => {
        const role = roles[roleId];
        if (role) {
            const roleName = role.getRolename();
            if (roleName === 'MB-PORTAL_OWNER_ROLE') {
                return true;
            }
        }
        return false;
    }).length > 0;
};

export const adminRole = (roles: RoleStore): string => {
    const adminRoles = Object.keys(roles).filter((roleId) => {
        const role = roles[roleId];
        if (role) {
            const roleName = role.getRolename();
            if (roleName === 'MB-PORTAL_OWNER_ROLE') {
                return true;
            }
        }
        return false;
    }).map((roleId) => roleId);
    return adminRoles[0];
};

export const tasksOfProject = (projectId: string, state: StoreState) => {
    const { projects, tasks } = state;
    const project = projects[projectId];
    if (project) {
        const taskList = project.getTaskidsList();
        return Object.entries(tasks).filter(([taskId, _]) => taskList.includes(taskId));
    } else {
        return [];
    }
};
