import { toast } from 'react-semantic-toasts';
import { CreateProjectRequest, CreateProjectResponse, DeleteProjectRequest, DeleteProjectResponse, ModifyProjectRequest, ModifyProjectResponse } from '../proto/usersroles_pb';
import { UsersRolesService } from '../proto/usersroles_pb_service';
import { StoreState } from '../store';
import { ThunkDispatch, ThunkResult } from '../store/types';
import { GrpcActionPayload, grpcRequest } from './grpc';

export const createProject = (name: string, description: string, taskIds?: string[]): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new CreateProjectRequest();
        request.setName(name);
        request.setDescription(description);
        if (taskIds) {
            request.setTaskidsList(taskIds);
        }
        const grpcAction: GrpcActionPayload<CreateProjectRequest, CreateProjectResponse> = {
            methodDescriptor: UsersRolesService.CreateProject,
            request,
            onStart: () => toast({
                title: 'Project created',
                description: 'Project ' + name + ' created'
            }),
            onError: (code, message) => toast({
                title: 'Project creation failed',
                description: 'Failed to create project ' + name + ': ' + message,
                type: 'error',
                time: 0,
            })
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const deleteProject = (id: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new DeleteProjectRequest();
        request.setProjectid(id);
        const grpcAction: GrpcActionPayload<DeleteProjectRequest, DeleteProjectResponse> = {
            methodDescriptor: UsersRolesService.DeleteProject,
            request,
            onStart: () => toast({
                title: 'Deleting project',
                description: ' Project ' + id + ' is being deleted',
                animation: 'fade right',
                icon: 'trash'
            }),
            onError: (code, message) => ({
                title: 'Project deletion failed',
                description: 'Failed to delete project ' + id + ': ' + message,
                type: 'error',
                time: 0,
            })
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const modifyProject = (projectId: string, name: string, description: string, taskIds: string[]): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new ModifyProjectRequest();
        request.setProjectid(projectId);
        request.setName(name);
        request.setDescription(description);
        request.setTaskidsList(taskIds);
        if (taskIds) {
            request.setTaskidsList(taskIds);
        }
        const grpcAction: GrpcActionPayload<ModifyProjectRequest, ModifyProjectResponse> = {
            methodDescriptor: UsersRolesService.ModifyProject,
            request,
            onEnd: () => toast({
                title: 'Modified project',
                description: 'Project ' + name + ' modified'
            }),
            onError: (code, message) => ({
                title: 'Project modification failed',
                description: 'Failed to modify project ' + name + ': ' + message,
                type: 'error',
                time: 0,
            })
        };
        dispatch(grpcRequest(grpcAction));
    };
};
