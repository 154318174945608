// Import FilePond styles
import { push } from 'connected-react-router';
import 'filepond/dist/filepond.min.css';
import * as React from 'react';
import { FilePond } from 'react-filepond';
import { connect } from 'react-redux';
import { toast } from 'react-semantic-toasts';
import { Divider, Header, Icon, Input, Message, Modal } from 'semantic-ui-react';
import { StoreState } from '../../store';
import { ThunkDispatch } from '../../store/types';
import { API_URL } from '../../utils/constants';

interface OwnProps {
}

interface DispatchProps {
    closeModal: () => void;
}

interface StatePropsFromStore {
    token: string;
}

interface OwnState {
    projectName: string;
    nameValid: boolean;
}

const initialState = {
    projectName: '',
    nameValid: true
};

type Props = OwnProps & DispatchProps & StatePropsFromStore;

class ImportProjectModal extends React.Component<Props, OwnState> {
    constructor(props: Props) {
        super(props);
        this.state = initialState;
    }

    public render() {
        const { token } = this.props;
        const { projectName, nameValid } = this.state;
        const importPdsIcon = (
            <Icon.Group>
                <Icon name="file archive outline" />
                <Icon corner={true} name="upload" />
            </Icon.Group>
        );

        let projectNameErrorMessage = '';
        if (!nameValid) {
            projectNameErrorMessage = 'Name should be less than 60 characters and conform regex [A-Za-z0-9-_]';
        }
        let available = true;
        if (!available) {
            projectNameErrorMessage = 'Name is already taken';
        }
        const projectNameValidAndAvailable = projectNameErrorMessage === '';

        const serverUrl = {
            headers: {
                Authorization: 'Bearer ' + token
            },
            method: 'PUT',
            url: API_URL + '/restore/' + (projectName.length > 0 ? projectName : 'Imported Project')
        };

        const server = {
            fetch: serverUrl,
            load: serverUrl,
            process: serverUrl,
            restore: serverUrl,
            revert: serverUrl,
        };
        return (
            <Modal
                size="tiny"
                open={true}
                closeIcon={true}
                className="modal-fix"
                onClose={() => this.props.closeModal()}
            >
                <Header>
                    {importPdsIcon}
                    &nbsp;Select project dump to import
                </Header>
                <Modal.Content scrolling={true}>
                    <Input
                        defaultValue={projectName}
                        error={!projectNameValidAndAvailable}
                        placeholder={'New project name'}
                        fluid={true}
                        onChange={this.handleNameChange}
                    />
                    {projectNameValidAndAvailable ? null : (
                        <Message
                            error={true}
                            header="Invalid Project Name"
                            content={projectNameErrorMessage}
                        />
                    )}
                    <Divider hidden={true} />
                    <FilePond
                        name="file"
                        allowMultiple={false}
                        disabled={!projectNameValidAndAvailable || projectName.length === 0}
                        onprocessfiles={() => {
                            this.props.closeModal();
                            toast({
                                title: 'Importing project',
                                description: 'Importing project ' + projectName
                            });
                        }
                        }
                        // @ts-ignore
                        server={server}
                    />
                </Modal.Content>
            </Modal>
        );
    }

    private handleNameChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;

        // validate name
        const regex = /^[A-Za-z0-9-_]+$/;

        let nameValid = false;
        if (value.length < 60 && value.match(regex)) {
            nameValid = true;
        }
        this.setState({
            ...this.state,
            projectName: value,
            nameValid,
        });
    }
}

function mapStateProps(state: StoreState): StatePropsFromStore {
    return {
        token: state.oidc.user!.id_token,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        closeModal: () => {
            dispatch(push('/'));
        },
    };
}

export default connect(mapStateProps, mapDispatchToProps)(ImportProjectModal);
