import React from 'react';
import { Beforeunload } from 'react-beforeunload';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { subscribeUserRoleChangeEvents, unsubscribeUserRoleChanges } from '../../actions/changeevents';
import { ThunkDispatch } from '../../store/types';
import { userIsAdminRedir, userIsAuthenticatedRedir } from '../../utils/auth';
import asyncComponent from '../AsyncComponent';
import NavBar from '../NavBar';
import ProjectList from '../ProjectList';

const AsyncAdmin = asyncComponent(() => import('../Admin'));

// @ts-ignore
//const ProtectedAsyncAdmin = userIsAuthenticatedRedir(AsyncAdmin);
const ProtectedAsyncAdmin = userIsAdminRedir(AsyncAdmin);

interface DispatchProps {
    doSubscribeChangeEvents: () => void;
    doUnsubscribeChangeEvents: () => void;
}

type Props = DispatchProps;

export class Portal extends React.PureComponent<Props> {

    public componentDidMount() {
        this.props.doSubscribeChangeEvents();
    }

    public componentWillUnmount() {
        this.props.doUnsubscribeChangeEvents();
    }

    public render = () => (
        <React.Fragment>
            <Beforeunload onBeforeunload={this.props.doUnsubscribeChangeEvents} />
            <NavBar />
            <Switch>
                <Route exact={false} path="/admin/:adminView?" component={ProtectedAsyncAdmin} />
                <Route exact={false} path="/" component={ProjectList} />
            </Switch>
        </React.Fragment>
    )
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        doSubscribeChangeEvents: () => {
            dispatch(subscribeUserRoleChangeEvents());

        },
        doUnsubscribeChangeEvents: () => {
            dispatch(unsubscribeUserRoleChanges());
        },
    };
}

export default connect(null, mapDispatchToProps)(Portal);
