import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import { StoreState } from '../store';
import { isAdmin } from './roleutils';

const userIsAuthenticatedDefaults = {
    // If selector is true, wrapper will not redirect
    // For example let's check that state contains user data
    authenticatedSelector: (state: StoreState) => {
        return state.oidc.user !== undefined;
    },
    authenticatingSelector: (state: StoreState) => {
        return state.oidc.isLoadingUser;
    },
    // A nice display name for this check
    wrapperDisplayName: 'UserIsAuthenticated'
};

// export const userIsAuthenticated = connectedAuthWrapper(userIsAuthenticatedDefaults);

export const userIsAuthenticatedRedir = connectedRouterRedirect({
    ...userIsAuthenticatedDefaults,
    // AuthenticatingComponent: Loading, // this is handled via redux and is hidden all the time
    // The url to redirect user to if they fail
    redirectPath: () => {
        const redirectPath = '/login';
        console.log('user is not authenticated - redirecting to: ' + redirectPath);
        return redirectPath;
    }
});

export const userIsAdminRedir = connectedRouterRedirect({
    allowRedirectBack: false,
    authenticatedSelector: (state: StoreState) => {
        const { oidc, users, roles } = state;
        if (oidc.user !== undefined) {
            const sub = oidc.user.profile.sub;
            const userr = users[sub];
            if (userr) {
                return isAdmin(userr, roles);
            }
        }
        return false;
    },
    authenticatingSelector: (state: StoreState) => {
        return state.oidc.isLoadingUser;
    },
    redirectPath: '/',
    wrapperDisplayName: 'UserIsAdmin'
});

const userIsNotAuthenticatedDefaults = {
    // Want to redirect the user when they are done loading and authenticated
    authenticatedSelector: (state: StoreState) => {
        return state.oidc.user === undefined && state.oidc.isLoadingUser === false;
    },
    wrapperDisplayName: 'UserIsNotAuthenticated'
};

// export const userIsNotAuthenticated = connectedAuthWrapper(userIsNotAuthenticatedDefaults);

export const userIsNotAuthenticatedRedir = connectedRouterRedirect({
    ...userIsNotAuthenticatedDefaults,
    allowRedirectBack: false,
    redirectPath: (state: StoreState, ownProps) => {
        console.log('user is authenticated - redirecting');
        const redirectPath = window.sessionStorage.getItem('redirectPath');
        if (redirectPath) {
            console.log('found redirect path from session storage: ' + redirectPath);
            window.sessionStorage.removeItem('redirectPath');
            return redirectPath;
        }
        const defaultRedirect = '/';
        console.log('redirecting to default: ' + defaultRedirect);
        return defaultRedirect;
    },
});
