import DOMPurify from 'dompurify';
import marked from 'marked';
import React from 'react';
import { Accordion, Icon } from 'semantic-ui-react';

interface ProjectDescriptionProps {
    description: string;
    active: boolean;
    onClick: (e: any, titleProps: any) => void;
}

const ProjectDescription = ({ description, active, onClick }: ProjectDescriptionProps) => {

    const projectDescriptionLines = description.split('\n');
    return projectDescriptionLines.length > 1 ? (
        <Accordion>
            <Accordion.Title
                active={active}
                index={0}
                onClick={onClick}
            >
                <Icon name="dropdown" />
                {projectDescriptionLines[0]}
            </Accordion.Title>
            <Accordion.Content active={active}>
                <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(marked(description)) }} />
            </Accordion.Content>
        </Accordion>
    ) : (
        <p>{description}</p>
    );
};

export default ProjectDescription;
