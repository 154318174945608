import React from 'react';
import { Button, Icon, Image, List, Placeholder } from 'semantic-ui-react';
import { LetterAvatar } from '../../utils/avatar';

interface TaskListProps {

    percentage: number;
    taskName: string;
    taskLink: string;
    appName: string;
}

const TaskList = ({ percentage, taskName, taskLink, appName }: TaskListProps) => {

    const avatarDataUrl = LetterAvatar(taskName, 64);

    const inner = (percentage !== 1) ? (
        <List.Item key={taskName} disabled={true}>
            <Placeholder>
                <Placeholder.Header image={true}>
                    <Placeholder.Line />
                    <Placeholder.Line />
                </Placeholder.Header>
            </Placeholder>
        </List.Item>
    ) : (
        <List.Item key={taskName} as="a" href={taskLink} target="_blank" >
            <List.Content floated="right">
                <Button basic={true} color="blue" icon={true} labelPosition="right">
                    <Icon color="blue" name="external" />
                    Open task
                </Button>
            </List.Content>
            <Image avatar={true} src={avatarDataUrl} />
            <List.Content>
                <List.Header>{taskName}</List.Header>
                <List.Description>
                    {appName}
                </List.Description>
            </List.Content>
        </List.Item>
    );

    return (
        inner
    );
};

export default TaskList;
