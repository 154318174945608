import { push } from 'connected-react-router';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Form, Header, Modal, Radio } from 'semantic-ui-react';
import { StoreState } from '../../store';
import { API_URL } from '../../utils/constants';
import { tasksOfProject } from '../../utils/roleutils';

interface ExportProjectModalProps {
    projectId: string;
}

const ExportProjectModal = () => {

    const { projectId }: ExportProjectModalProps = useParams();

    const projectName = useSelector((state: StoreState) => {
        const project = state.projects[projectId];
        return project ? project.getName() : projectId;
    });

    const appsAndOptions = useSelector((state: StoreState) => {
        const tasks = tasksOfProject(projectId, state);
        const { portalApplications } = state;
        const apps = tasks.map((task) => {
            return Object.values(portalApplications).find((app) => app.getName() === task[1].getAppname());
        })
        .filter((app) => app !== null)
        .map((app) => {
            return {
                appName: app!.getName(),
                exportOptions: app!.getExportoptionsList()
            };
        })
        .filter((appAndList) => appAndList.exportOptions.length > 0);
        console.log('useSelector apps ' + apps.length);
        return apps;
    });

    const token = useSelector(({ oidc }: StoreState) => {
        if (oidc.user) {
            return oidc.user.id_token;
        }
        return '';
    });

    const [exporting, setExporting] = useState(false);
    const [exportOption, setExportOption] = useState('');

    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch(push('/'));
    };

    let url = `${API_URL}/${projectId}/backup?token=${token}`
        if (exportOption !== '') {
        const params = new URLSearchParams({
            exportOptions: exportOption
        });
        url = url + '&' + params;
    };

/*     const doExportProject = (closeModalAfterError: boolean) => {
        setExporting(true);

        let url = '/' + projectId + '/backup';
        if (exportOption !== '') {
            const params = new URLSearchParams({
                exportOptions: exportOption
            });
            url = url + '?' + params;
        }
        const fileName = 'project-' + projectId + '.zip';

        dispatch(download(url, fileName, () => {
            setExporting(false);
            closeModal();
        }, () => {
            setExporting(false);
            if (closeModalAfterError) {
                closeModal();
            }
        }));
    }; */

    const appList = appsAndOptions.map(({ appName, exportOptions }) => {
        const exportOptionsList = exportOptions.map((opt) => {
            const optt = appName + '::' + opt;
            return (
                <Form.Field key={opt}>
                    <Radio
                        name="radioGroup"
                        value={optt}
                        label={opt}
                        checked={exportOption === optt}
                        onChange={() => setExportOption(optt)}
                    />
                </Form.Field>
            );
        });

        return (
            <Form key={appName}>
                <Header>{appName}</Header>
                <Form.Field>
                    Select export options for {appName}
                </Form.Field>
                {exportOptionsList}
            </Form>
        );
    });

    return (
        <Modal
            open={true}
            size={'tiny'}
            onClose={closeModal}
            closeIcon={true}
            className={'modal-fix'}
        >
            <Modal.Header>
                Export project {projectName}
            </Modal.Header>
            <Modal.Content>
                {appList}
            </Modal.Content>
            <Modal.Actions>
                <Button
                    as={'a'}
                    href={url}
                    target={"_blank"}
                    disabled={exporting}
                    content="Export"
                    labelPosition="right"
                    icon="checkmark"
                    onClick={() => setExporting(true)}
                    primary={true}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default ExportProjectModal;
