import React from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

interface OwnProps {
    onChange: (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void;
    users: string[];
}

class UserSearchInput extends React.Component<OwnProps> {

    public render() {
        const { users, onChange } = this.props;
        const userOptions = users.map((user) => {
            return {
                text: user,
                value: user,
            };
        });
        return (
            <Dropdown
                placeholder="Select user"
                fluid={true}
                selection={true}
                options={userOptions}
                multiple={true}
                search={true}
                onChange={onChange}
            />
        );
    }
}

export default UserSearchInput;