import { grpc } from '@improbable-eng/grpc-web';
import produce from 'immer';
import { getType } from 'typesafe-actions';
import { IStoreActions } from '../actions';
import actions from '../actions/changeevents';

export interface SubscriptionStore {
    [id: string]: grpc.Request;
}

export default function(state: SubscriptionStore = {}, action: IStoreActions): SubscriptionStore {
    return produce(state, (draftState) => {
        switch (action.type) {
            case getType(actions.listeningUserRoleChanges): {
                console.log('listening projects');
                draftState.listProjectSubscription = action.payload;
                break;
            }
            case getType(actions.doUnsubscribeUserRoleChanges): {
                if (state.listProjectSubscription) {
                    console.log('closing grpc chananel');
                    state.listProjectSubscription.close();
                }
                delete draftState.listProjectSubscription;
                break;
            }
            default:
                return state;
        }
    });
}
