import * as jspb from 'google-protobuf';
import produce from 'immer';
import { getType } from 'typesafe-actions';
import { IStoreActions } from '../actions';
import actions from '../actions/changeevents';
import { ChangeOperation, UsersRolesChangeEvent, TargetCollection, TargetCollectionMap } from '../proto/usersroles_pb';
import { ChangeEventStore } from '../store/types';

const initialState = {};

const composed = <T extends jspb.Message>
    (
        targetCollection: Number,
        get: () => T | undefined,
        keyFunc: (changeEvent: UsersRolesChangeEvent) => string = defaultKeyFunction,
    ) => (
        state: ChangeEventStore<T> = initialState,
        action: IStoreActions
    ): ChangeEventStore<T> => {
        return produce(state, (draftState) => {
            if (action.type === getType(actions.receiveUserRoleChangeEvent) && action.payload.getTargetcollection() === targetCollection) {

                const changeEvent = action.payload;
                const key = keyFunc(changeEvent);
                if (changeEvent.getChangeoperation() === ChangeOperation.DELETE) {
                    if (targetCollection === TargetCollection.PROJECTS) {
                        console.log("deleting " + targetCollection + " with key " + key);
                    }
                    delete draftState[key];
                } else {
                    // @ts-ignore
                    draftState[key] = get.call(changeEvent)!;
                    if (targetCollection === TargetCollection.PROJECTS) {
                        console.log("added " + targetCollection + " with key " + key);
                    }
                }
            }

        });
    };

const defaultKeyFunction = (changeEvent: UsersRolesChangeEvent) => changeEvent.getContentid();

export default composed;
