import { push } from 'connected-react-router';
import React from 'react';
import { connect } from 'react-redux';
import { CallbackComponent } from 'redux-oidc';
import { ThunkDispatch } from '../../store/types';
import userManager from '../../utils/userManager';

interface DispatchProps {
    doRedirect: () => void;
}

type Props = DispatchProps;

class CallbackPage extends React.Component<Props> {
    public render() {
        const { doRedirect } = this.props;
        // just redirect to '/' in both cases
        return (
            <CallbackComponent
                userManager={userManager}
                successCallback={() => doRedirect()}
                errorCallback={(error) => {
                    doRedirect();
                    console.error(error);
                }}
            >
                <div>Redirecting...</div>
            </CallbackComponent>
        );
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        doRedirect: () => {
            dispatch(push('/'));
        }
    };
}

export default connect(null, mapDispatchToProps)(CallbackPage);
