import React from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Input } from 'semantic-ui-react';
import { modifyProject } from '../../actions/projects';
import { StoreState } from '../../store';
import { ThunkDispatch } from '../../store/types';

interface OwnProps {
    projectId: string;
}

interface StatePropsFromStore {
    projectDescription: string;
    projectName: string;
    taskIds: string[];
}

interface DispatchProps {
    doChangeProjectName: (projectId: string, newName: string, description: string, taskIds: string[]) => void;
}

interface OwnState {
    edit: boolean;
    newName: string;
}

const editProjectIcon = (
    <Icon.Group>
        <Icon name="code branch" />
        <Icon corner={true} name="edit" />
    </Icon.Group>
);

type Props = OwnProps & DispatchProps & StatePropsFromStore;
class ProjectNameModalHeader extends React.Component<Props, OwnState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            edit: false,
            newName: '',
        };
    }

    public render() {
        const { projectName } = this.props;
        const { edit } = this.state;
        return edit ? (
            <React.Fragment>
                <Input fluid={true} size={'mini'} action={true} defaultValue={projectName} onChange={this.handleChange}>
                    <input className="mini" />
                    <Button size="mini" onClick={this.handleSubmit}>
                        Save
                    </Button>
                    <Button size="mini" onClick={this.cancelEditing}>
                        Cancel
                    </Button>
                </Input>
            </React.Fragment>
        ) : (
            <span style={{paddingTop: '5px', paddingBottom: '5px'}}>
                {editProjectIcon}
                &nbsp;Edit project {projectName}
                <Button size="mini" basic={true} animated={true} style={{float: 'right'}} onClick={this.startEditing}>
                    <Button.Content hidden={true}>Edit</Button.Content>
                    <Button.Content visible={true}>
                        <Icon name="edit" />
                    </Button.Content>
                </Button>
            </span>
        );
    }

    private handleSubmit = () => {
        const { projectId, projectDescription, taskIds, doChangeProjectName } = this.props;
        doChangeProjectName(projectId, this.state.newName, projectDescription, taskIds);
        this.cancelEditing();
    }

    private handleChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            newName: event.currentTarget.value,
        });
    }

    private startEditing = () => {
        this.setState({
            ...this.state,
            edit: true,
        });
    }

    private cancelEditing = () => {
        this.setState({
            ...this.state,
            edit: false,
        });
    }
}

function mapStateToProps(state: StoreState, ownProps: OwnProps): StatePropsFromStore {
    const { projects } = state;
    const project = projects[ownProps.projectId];
    const projectDescription = project ? project.getDescription() : '';
    const projectName = project ? project.getName() : '';
    const taskIds = project ? project.getTaskidsList() : [];
    return {
        ...ownProps,
        projectDescription,
        projectName,
        taskIds,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        doChangeProjectName: (projectId: string, newName: string, description: string, taskIds: string[]) => {
            dispatch(modifyProject(projectId, newName, description, taskIds));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectNameModalHeader);
