import { toast } from 'react-semantic-toasts';
import { createAction } from 'typesafe-actions';
import { CreateUserRequest, CreateUserResponse, DeleteUserRequest, DeleteUserResponse, VerifyUserRequest, VerifyUserResponse } from '../proto/usersroles_pb';
import { UsersRolesService } from '../proto/usersroles_pb_service';
import { StoreState } from '../store';
import { ThunkDispatch, ThunkResult } from '../store/types';
import { fetchApi, FetchInfo } from './api';
import { GrpcActionPayload, grpcRequest } from './grpc';

const updateAuthUserToken = createAction('UPDATE_AUTH_USER_TOKEN', (resolve) => {
    return (token: string) => resolve(token);
});

export const doLogout = (): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const tokenFromStorage = window.localStorage.getItem('token');
        if (tokenFromStorage !== null) {
            window.localStorage.removeItem('token');
            // we already have a token, let's see if it is valid
        }
        const loginPath = '/logout';

        const fetchInfo: FetchInfo = {
            label: 'foo',
            onFailure: () => {
                // ok, fetch login failed, lets do window.location.replace()
                //const fullLogin = baseURL + loginPath;
                //window.location.href = fullLogin;
            },
            onSuccess: () => {
                const token = '';
            },
            request: loginPath,
        };
        dispatch(fetchApi(fetchInfo));

    };
};

export const createUser = (email: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new CreateUserRequest();
        request.setEmail(email);
        const grpcAction: GrpcActionPayload<CreateUserRequest, CreateUserResponse> = {
            methodDescriptor: UsersRolesService.CreateUser,
            request,
            onStart: () => toast({
                title: 'User created',
                description: 'User ' + email + ' created'
            }),
            onError: (code, message) => toast({
                title: 'User creation failed',
                description: 'Failed to create user ' + email + ': ' + message,
                type: 'error',
                time: 0,
            })
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const deleteUser = (userId: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new DeleteUserRequest();
        request.setUserid(userId);
        const grpcAction: GrpcActionPayload<DeleteUserRequest, DeleteUserResponse> = {
            methodDescriptor: UsersRolesService.DeleteUser,
            request,
            onStart: () => toast({
                title: 'User deleted',
                description: 'User ' + userId + ' deleted'
            }),
            onError: (code, message) => toast({
                title: 'User deletion failed',
                description: 'Failed to delete user ' + userId + ': ' + message,
                type: 'error',
                time: 0,
            })
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const verifyUser = (userId: string, verify: boolean): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new VerifyUserRequest();
        request.setUserid(userId);
        request.setVerify(verify);
        const grpcAction: GrpcActionPayload<VerifyUserRequest, VerifyUserResponse> = {
            methodDescriptor: UsersRolesService.VerifyUser,
            request,
            onStart: () => toast({
                title: 'User verified',
                description: 'User ' + userId + ' verified'
            }),
            onError: (code, message) => toast({
                title: 'User verification failed',
                description: 'Failed to verify user ' + userId + ': ' + message,
                type: 'error',
                time: 0,
            })
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export default {
    userLogout: createAction('USER_LOGOUT'),
    updateAuthUserToken,
};
