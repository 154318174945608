import React, { Component } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

export default function asyncComponent(importComponent: any) {

    interface OwnProps {}

    interface OwnState {
        component: Component | null;
    }
    class AsyncComponent extends Component<OwnProps, OwnState> {
        constructor(props: OwnProps) {
            super(props);

            this.state = {
                component: null
            };
        }

        public async componentDidMount() {
            const { default: component } = await importComponent();

            this.setState({
                component
            });
        }

        public render() {
            const C = this.state.component;
            // @ts-ignore
            return C ? <C {...this.props} /> : (
                <Dimmer inverted={true} active={true}>
                    <Loader>Loading...</Loader>
                </Dimmer>
            );
        }
    }

    return AsyncComponent;
}