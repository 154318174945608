import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from '../../store/types';
import userManager from '../../utils/userManager';

interface DispatchProps {
    logout: () => void;
}

type Props = DispatchProps;

class Logout extends React.Component<Props> {

    public componentDidMount() {
        //userManager.startSilentRenew();
        //userManager.signinSilent().then(user => console.log(user));
        //userManager.signoutRedirect();
    }

    public render() {
        return (
            <div>Signing out..</div>
        );
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        logout: () => {
            userManager.startSilentRenew();
            userManager.signinSilent().then(user => console.log(user));
            //userManager.signoutRedirect();
        }
    };
}

export default connect(mapDispatchToProps)(Logout);
