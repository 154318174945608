import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Card, Divider, Dropdown, DropdownProps, Icon, Input } from 'semantic-ui-react';
import { ThunkDispatch } from '../../store/types';

interface OwnProps {
    onInputChanged: (input: string) => void;
    onSortCriteriaChange: (criteria: string, direction: string) => void;
}

interface StatePropsFromStore {
    
}

interface DispatchProps {
    createProject: () => void;
    importProject: () => void;
}

interface OwnState {
    name: string;
    description: string;
    open: boolean;
    sortValue: string;
}

const initialState = {
    description: '',
    name: '',
    sortValue: 'created:desc',
    open: false,
};

const sortingOptions = [
    { key: 'created:desc', text: 'Newest', value: 'created:desc', icon: 'sort numeric descending'  },
    { key: 'created:asc', text: 'Oldest', value: 'created:asc', icon: 'sort numeric ascending' },
    { key: 'name:asc', text: 'Alphabetic', value: 'name:asc', icon: 'sort alphabet ascending' },
    { key: 'name:desc', text: 'Reverse alphabetic', value: 'name:desc', icon: 'sort alphabet descending' },
];

type Props = OwnProps & DispatchProps & StatePropsFromStore;

class CreateProjectCard extends React.Component<Props, OwnState> {
    constructor(props: Props) {
        super(props);
        this.state = initialState;
    }

    public render() {
        const dd = (
            <Dropdown
                onChange={this.handleSortCriteriaChange}
                selection={true}
                direction={'left'}
                options={sortingOptions}
                value={this.state.sortValue}
                style={{minWidth: '7em'}}
            />
        );
        return (
            <Card color={'blue'} fluid={true}>
                <Card.Content>
                    <Button
                        primary={true}
                        fluid={true}
                        color={'blue'}
                        onClick={this.createProject}
                    >
                        <Icon.Group>
                            <Icon name="code branch" />
                            <Icon corner={true} size={'mini'} name="add" />
                        </Icon.Group>
                        &nbsp;Create project
                    </Button>
                    <Divider hidden={true} />
                    <Button basic={true} fluid={true} onClick={this.importProject} >Import project</Button>
                    <Divider hidden={true} />
                    <Input
                        fluid={true}
                        labelPosition={'right'}
                        label={dd}
                        placeholder={'Filter projects...'}
                        onChange={this.handleFilterChange}
                    />
                </Card.Content>
            </Card>
        );
    }

    private createProject = () => {
        this.props.createProject();
    }

    private importProject = () => {
        this.props.importProject();
    }

    private handleFilterChange = (event: React.FormEvent<HTMLInputElement>) => {
        this.props.onInputChanged(event.currentTarget.value);
    }

    private handleSortCriteriaChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const critDir = (data.value as string).split(':');
        this.props.onSortCriteriaChange(critDir[0], critDir[1]);
        this.setState({
            ...this.state,
            sortValue: data.value as string,
        });
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        createProject: () => {
            dispatch(push('/create'));
        },
        importProject: () => {
            dispatch(push('/import'));
        },
    };
}

export default connect(null, mapDispatchToProps)(CreateProjectCard);