// package: 
// file: usersroles.proto

var usersroles_pb = require("./usersroles_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var UsersRolesService = (function () {
  function UsersRolesService() {}
  UsersRolesService.serviceName = "UsersRolesService";
  return UsersRolesService;
}());

UsersRolesService.UsersRolesChangeEvents = {
  methodName: "UsersRolesChangeEvents",
  service: UsersRolesService,
  requestStream: false,
  responseStream: true,
  requestType: usersroles_pb.UsersRolesChangeEventRequest,
  responseType: usersroles_pb.UsersRolesChangeEvent
};

UsersRolesService.CreateUser = {
  methodName: "CreateUser",
  service: UsersRolesService,
  requestStream: false,
  responseStream: false,
  requestType: usersroles_pb.CreateUserRequest,
  responseType: usersroles_pb.CreateUserResponse
};

UsersRolesService.DeleteUser = {
  methodName: "DeleteUser",
  service: UsersRolesService,
  requestStream: false,
  responseStream: false,
  requestType: usersroles_pb.DeleteUserRequest,
  responseType: usersroles_pb.DeleteUserResponse
};

UsersRolesService.VerifyUser = {
  methodName: "VerifyUser",
  service: UsersRolesService,
  requestStream: false,
  responseStream: false,
  requestType: usersroles_pb.VerifyUserRequest,
  responseType: usersroles_pb.VerifyUserResponse
};

UsersRolesService.ChangeUserRole = {
  methodName: "ChangeUserRole",
  service: UsersRolesService,
  requestStream: false,
  responseStream: false,
  requestType: usersroles_pb.ChangeUserRoleRequest,
  responseType: usersroles_pb.ChangeUserRoleResponse
};

UsersRolesService.ChangeGroupRole = {
  methodName: "ChangeGroupRole",
  service: UsersRolesService,
  requestStream: false,
  responseStream: false,
  requestType: usersroles_pb.ChangeGroupRoleRequest,
  responseType: usersroles_pb.ChangeGroupRoleResponse
};

UsersRolesService.CreateGroup = {
  methodName: "CreateGroup",
  service: UsersRolesService,
  requestStream: false,
  responseStream: false,
  requestType: usersroles_pb.CreateGroupRequest,
  responseType: usersroles_pb.CreateGroupResponse
};

UsersRolesService.DeleteGroup = {
  methodName: "DeleteGroup",
  service: UsersRolesService,
  requestStream: false,
  responseStream: false,
  requestType: usersroles_pb.DeleteGroupRequest,
  responseType: usersroles_pb.DeleteGroupResponse
};

UsersRolesService.AddUserToGroup = {
  methodName: "AddUserToGroup",
  service: UsersRolesService,
  requestStream: false,
  responseStream: false,
  requestType: usersroles_pb.AddUserToGroupRequest,
  responseType: usersroles_pb.AddUserToGroupResponse
};

UsersRolesService.DeleteUserFromGroup = {
  methodName: "DeleteUserFromGroup",
  service: UsersRolesService,
  requestStream: false,
  responseStream: false,
  requestType: usersroles_pb.DeleteUserFromGroupRequest,
  responseType: usersroles_pb.DeletedUserFromGroupResponse
};

UsersRolesService.CreateProject = {
  methodName: "CreateProject",
  service: UsersRolesService,
  requestStream: false,
  responseStream: false,
  requestType: usersroles_pb.CreateProjectRequest,
  responseType: usersroles_pb.CreateProjectResponse
};

UsersRolesService.DeleteProject = {
  methodName: "DeleteProject",
  service: UsersRolesService,
  requestStream: false,
  responseStream: false,
  requestType: usersroles_pb.DeleteProjectRequest,
  responseType: usersroles_pb.DeleteProjectResponse
};

UsersRolesService.ModifyProject = {
  methodName: "ModifyProject",
  service: UsersRolesService,
  requestStream: false,
  responseStream: false,
  requestType: usersroles_pb.ModifyProjectRequest,
  responseType: usersroles_pb.ModifyProjectResponse
};

exports.UsersRolesService = UsersRolesService;

function UsersRolesServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

UsersRolesServiceClient.prototype.usersRolesChangeEvents = function usersRolesChangeEvents(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(UsersRolesService.UsersRolesChangeEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

UsersRolesServiceClient.prototype.createUser = function createUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UsersRolesService.CreateUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UsersRolesServiceClient.prototype.deleteUser = function deleteUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UsersRolesService.DeleteUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UsersRolesServiceClient.prototype.verifyUser = function verifyUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UsersRolesService.VerifyUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UsersRolesServiceClient.prototype.changeUserRole = function changeUserRole(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UsersRolesService.ChangeUserRole, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UsersRolesServiceClient.prototype.changeGroupRole = function changeGroupRole(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UsersRolesService.ChangeGroupRole, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UsersRolesServiceClient.prototype.createGroup = function createGroup(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UsersRolesService.CreateGroup, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UsersRolesServiceClient.prototype.deleteGroup = function deleteGroup(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UsersRolesService.DeleteGroup, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UsersRolesServiceClient.prototype.addUserToGroup = function addUserToGroup(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UsersRolesService.AddUserToGroup, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UsersRolesServiceClient.prototype.deleteUserFromGroup = function deleteUserFromGroup(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UsersRolesService.DeleteUserFromGroup, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UsersRolesServiceClient.prototype.createProject = function createProject(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UsersRolesService.CreateProject, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UsersRolesServiceClient.prototype.deleteProject = function deleteProject(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UsersRolesService.DeleteProject, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UsersRolesServiceClient.prototype.modifyProject = function modifyProject(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UsersRolesService.ModifyProject, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.UsersRolesServiceClient = UsersRolesServiceClient;

