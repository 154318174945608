import React from 'react';
import { FilePond } from 'react-filepond';
import { connect } from 'react-redux';
import { Button, Header, Icon, List, Modal, Tab } from 'semantic-ui-react';
import { StoreState } from '../../store';
import { API_URL } from '../../utils/constants';

interface OwnProps {
    open: boolean;

    projectId: string;

    modalClosed: () => void;
}

interface StoreProps {
    token: string;
}

type Props = OwnProps & StoreProps;

interface OwnState {
    foldersAndFiles: Map<string, ImportFile[]>;
}

interface ImportFile {
    name: string;
    contentType: string;
}

const initialState = {
    foldersAndFiles: new Map()
};

class ImportFileModal extends React.Component<Props, OwnState> {
    constructor(props: Props) {
        super(props);

        this.state = initialState;
    }

    public componentDidMount() {
        const { projectId, token } = this.props;
        fetch(API_URL + '/files/' + (projectId.length > 0 ? projectId : 'Imported Project'), {
            headers: {
                Authorization: 'Bearer ' + token
            },
            method: 'GET',
        }).then((response) => {
            response.json()
            .then((files: ImportFile[]) => {
                const foldersAndFiles = new Map();
                files.forEach((file) => {
                    console.log('file ' + file.name);
                    const folderAndName = file.name.split('/');
                    const folderName = folderAndName[0];
                    const fileName = folderAndName[1];

                    console.log('folderName is ' + folderName);
                    console.log('fileName is ' + fileName);

                    let filesUnderFolder = foldersAndFiles.get(folderName);
                    if (!filesUnderFolder) {
                        filesUnderFolder = [];
                    }
                    filesUnderFolder.push({
                        name: fileName,
                        contentType: file.contentType
                    });
                    foldersAndFiles.set(folderName, filesUnderFolder);
                });

                this.setState({
                    ...this.state,
                    foldersAndFiles
                });
            });
        }).catch((e) => {
            console.error(e);
        });
    }

    public render() {

        const { token, projectId, modalClosed } = this.props;
        const { foldersAndFiles } = this.state;

        const serverUrl = {
            headers: {
                Authorization: 'Bearer ' + token
            },
            method: 'PUT',
            url: API_URL + '/upload/' + (projectId.length > 0 ? projectId : 'Imported Project')
        };

        const server = {
            fetch: serverUrl,
            load: serverUrl,
            process: serverUrl,
            restore: serverUrl,
            revert: serverUrl,
        };

        const fileList = Array.from( foldersAndFiles ).map(([key, files], index) => {
            const filesUnderFolder = files.map((file, index2) => {
                return (
                    <List.Item key={projectId + file.name} >
                        <List.Content floated="right">
                            <Button icon={true} labelPosition="right" size="small" basic={false} >
                                <Icon name="trash" />
                                Delete
                            </Button>
                        </List.Content>
                        <List.Icon name="file" />
                        <List.Content>
                            <List.Header>
                                {file.name}
                            </List.Header>
                            <List.Description>
                                {file.contentType}
                            </List.Description>
                        </List.Content>
                    </List.Item>
                );
            });

            return (
                <List.Item key={key}>
                    <List.Icon name="folder" />
                    <List.Content>
                        <List.Header>{key}</List.Header>
                        <List.Description>{filesUnderFolder.length} files</List.Description>
                        <List.List selection="true" >
                            {filesUnderFolder}
                        </List.List>
                    </List.Content>
                </List.Item>
            );
        });

        const panes = [
            {
                menuItem: { key: 'import', icon: 'file', content: 'Import'},
                render: () => (
                    <Tab.Pane>
                        <List verticalAlign="middle" divided={true}>
                            {fileList}
                        </List>
                    </Tab.Pane>
                )
            },
            {
                menuItem: { key: 'upload', icon: 'upload', content: 'Upload'},
                render: () => (
                    <Tab.Pane>
                        <FilePond
                            name="file"
                            allowMultiple={false}
                            // disabled={showError || projectId.length === 0}
                            // @ts-ignore
                            onprocessfiles={modalClosed}
                            // @ts-ignore
                            server={server}
                        />
                    </Tab.Pane>
                )
            }
        ];

        const { open } = this.props;
        return (
            <Modal
                open={open}
                size="small"
                onClose={modalClosed}
                closeIcon={true}
            >
                <Header>
                    Import files
                </Header>
                <Modal.Content scrolling={true}>
                    <Tab panes={panes} />
                </Modal.Content>
            </Modal>
        );
    }
}

function mapStateToProps(state: StoreState): StoreProps {
    return {
        token: state.oidc.user!.id_token,
    };
}
export default connect(mapStateToProps)(ImportFileModal);
