import { TargetCollection, Token, UsersRolesChangeEvent } from '../proto/usersroles_pb';
import { ChangeEventStore } from '../store/types';
import composable from './composable';

export interface TokenStore extends ChangeEventStore<Token> {}

export default composable<Token>(
    TargetCollection.TOKENS,
    UsersRolesChangeEvent.prototype.getToken
);
