import isEqual from 'lodash.isequal';
import * as React from 'react';
import { connect } from 'react-redux';
import { OidcState } from '../../reducers/auth';
import { StoreState } from '../../store';
import userManager from '../../utils/userManager';

interface StatePropsFromStore {

    oidcState: OidcState;
}

type Props = StatePropsFromStore;

class Login extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    public componentDidMount() {
        // this is a no op - everything should happen in update
    }

    public componentDidUpdate() {
        const { oidcState } = this.props;
        if (oidcState.userExpired) {
            userManager.signinRedirect();
        }
    }

    public shouldComponentUpdate(nextProps: Props) {
        return !isEqual(nextProps.oidcState, this.props.oidcState);
    }

    public render() {
        return (
            <div>Redirecting...</div>
        );
    }
}

function mapStateToProps(state: StoreState): StatePropsFromStore {
    const { oidcState } = state;
    return {
        oidcState,
    };
}

export default connect(mapStateToProps)(Login);
