const DEFAULT_VARS_VALUES = [
    '$PORTAL_SERVER_URI',
    '$OIDC_AUTHORITY_URI',
    '$OIDC_CLIENT_ID',
    '$OIDC_CLIENT_SECRET',
    '$TRIAL_ENABLED'
];

const isEmpty = (value: string | undefined | null): boolean => {
    return value === null || value === undefined || value === '' || DEFAULT_VARS_VALUES.indexOf(value) > -1;
};

// @ts-ignore
let PORTAL_SERVER_URI = window.__PORTAL_SERVER_URI__;
if (isEmpty(PORTAL_SERVER_URI)) {
    PORTAL_SERVER_URI = process.env.REACT_APP_PORTAL_SERVER_URI;
}

export const basename = PORTAL_SERVER_URI;

export const API_URL = basename + '/api';

export const GRPC_API_URL = basename + '/grpc';

// @ts-ignore
let OIDC_AUTHORITY_URI = window.__OIDC_AUTHORITY_URI__;
if (isEmpty(OIDC_AUTHORITY_URI)) {
    OIDC_AUTHORITY_URI = process.env.REACT_APP_OIDC_AUTHORITY_URI;
}

export const authority = OIDC_AUTHORITY_URI;

// @ts-ignore
let OIDC_CLIENT_ID = window.__OIDC_CLIENT_ID__;
if (isEmpty(OIDC_CLIENT_ID)) {
    OIDC_CLIENT_ID = process.env.REACT_APP_OIDC_CLIENT_ID ? process.env.REACT_APP_OIDC_CLIENT_ID : 'client';
}
export const client_id = OIDC_CLIENT_ID;

// @ts-ignore
let OIDC_CLIENT_SECRET = window.__OIDC_CLIENT_SECRET__;
if (isEmpty(OIDC_CLIENT_SECRET)) {
    OIDC_CLIENT_SECRET = process.env.REACT_APP_OIDC_CLIENT_SECRET ? process.env.REACT_APP_OIDC_CLIENT_SECRET : 'secret';
}
export const client_secret = OIDC_CLIENT_SECRET;

// @ts-ignore
let TRIAL_ENABLED = window.__TRIAL_ENABLED__;
if (isEmpty(TRIAL_ENABLED)) {
    TRIAL_ENABLED = process.env.REACT_APP_TRIAL_ENABLED ? process.env.REACT_APP_TRIAL_ENABLED : '';
}
export const P_TRIAL_ENABLED =  true; //TRIAL_ENABLED !== '';
