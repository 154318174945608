import { ConnectedRouter } from 'connected-react-router';
import JavascriptTimeAgo from 'javascript-time-ago';
// The desired locales.
// @ts-ignore
import en from 'javascript-time-ago/locale/en';
// @ts-ignore
import fi from 'javascript-time-ago/locale/fi';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { OidcProvider, processSilentRenew } from 'redux-oidc';
import 'semantic-ui-css/semantic.min.css';
import Application from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import configureStore, { history } from './store';
import userManager from './utils/userManager';

import { SemanticToastContainer } from 'react-semantic-toasts';
import 'react-semantic-toasts/styles/react-semantic-alert.css';

if (process.env.NODE_ENV === 'development') {
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
      trackAllPureComponents: true,
    });
}

if (window.location.pathname === '/silent-renew') {
    processSilentRenew();
} else {
    // Initialize the desired locales.
    JavascriptTimeAgo.locale(en);
    JavascriptTimeAgo.locale(fi);

    const store = configureStore();

    ReactDOM.render(
        (
            <React.Fragment>
                <SemanticToastContainer position="bottom-right" />
                <Provider store={store}>
                    <OidcProvider store={store} userManager={userManager}>
                        <ConnectedRouter history={history}>
                            <Application />
                        </ConnectedRouter>
                    </OidcProvider>
                </Provider>
            </React.Fragment>
        ),
        document.getElementById('root')
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
}
