import isEqual from 'lodash.isequal';
import * as React from 'react';
import { connect } from 'react-redux';
import ReactTimeAgo from 'react-time-ago';
import { Card, Icon, List } from 'semantic-ui-react';
import { Task, TaskStatusUpdate } from '../../proto/usersroles_pb';
import { StoreState } from '../../store';
import { tasksOfProject } from '../../utils/roleutils';
import ProjectDescription from './ProjectDescription';
import ProjectDropdown from './ProjectDropdown';
import TaskList from './TaskList';

interface OwnProps {
    projectId: string;
}

interface StatePropsFromStore {
    projectName: string;
    projectDescription: string;
    created: number;
    tasks: Array<[string, Task]>;

    statusUpdates: Array<[string, TaskStatusUpdate]>;
    users: number;
    userIsVerified: boolean;

    userIsOwnerOfProject: boolean;
}

interface DispatchProps {
    //doImportFile: (projectId: string) => void;

}

interface OwnState {
    open: boolean;
    activeIndex: number;

}

type Props = OwnProps & StatePropsFromStore & DispatchProps;

class ProjectCard extends React.Component<Props, OwnState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            open: false,
            activeIndex: -1,
        };
    }

    public shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<OwnState>, nextContext: any) {
        if (!isEqual(this.state, nextState)) {
            return true;
        }
        if (!isEqual(this.props, nextProps)) {
            return true;
        }
        return false;
    }

    public render() {
        const {
            created,
            projectId,
            projectName,
            projectDescription,
            tasks,
            statusUpdates,
            users,
        } = this.props;

        const taskListComponents = tasks.map(([taskId, task]) => {
            const taskName = task.getName();
            const appName = task.getAppname();
            const taskUrl = task.getTaskurl() + '/projects/';
            // const taskUrl = "hmi-testing-k8s.simupedia.com/"
            const taskLink = window.location.protocol + '//' + taskUrl + '' + projectId + '/';

            const statusUpdate = statusUpdates.find(([_, update]) => update.getTaskid() === taskId);
            const percentage = statusUpdate ? statusUpdate[1].getPercentage() : 0;
            return (
                <TaskList
                    key={taskName}
                    percentage={percentage}
                    taskName={taskName}
                    taskLink={taskLink}
                    appName={appName}
                />
            );
        });

        const { activeIndex } = this.state;
        const description = <ProjectDescription description={projectDescription} active={activeIndex === 0} onClick={this.handleClick} />;

        const { userIsVerified, userIsOwnerOfProject } = this.props;
        const createdTimeAgo = created ? (<ReactTimeAgo date={new Date(created)} />) : null;

        return (
            <>
                <Card color={'blue'} fluid={true}>
                    <Card.Content>
                        {userIsVerified && userIsOwnerOfProject ? (
                            <ProjectDropdown projectId={projectId} projectName={projectName} />
                        ) : null}
                        <Card.Header>{projectName}</Card.Header>
                        <Card.Meta>Created {createdTimeAgo}</Card.Meta>
                        <Card.Description>
                            {description}
                        </Card.Description>
                        <List relaxed={true} selection={true} verticalAlign="middle">
                            {taskListComponents}
                        </List>
                    </Card.Content>
                    <Card.Content extra={true}>
                        <span>
                            <Icon name="users" />
                            {users} Users
                        </span>
                    </Card.Content>
                </Card>
            </>
        );
    }

    private handleClick = (e: any, titleProps: any) => {
        const { index } = titleProps;
        const { activeIndex } = this.state;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({
            ...this.state,
            activeIndex: newIndex
        });
    }

}

function mapStateToProps(state: StoreState, ownProps: OwnProps): StatePropsFromStore {
    const { projectId } = ownProps;
    const { roles, projects, taskStatusUpdates, users, oidc } = state;
    const project = projects[projectId];
    const projectName = project ? project.getName() : '';
    const projectDescription = project ? project.getDescription() : '';

    const created = project ? project.getCreated()!.toDate().getTime() : 0;

    const taskList = project ? project.getTaskidsList(): [];

    const taskss = tasksOfProject(projectId, state);

    const statusUpdates = Object.entries(taskStatusUpdates)
        .filter(([taskStatusId, taskStatusUpdate]) => taskList.includes(taskStatusUpdate.getTaskid()) && taskStatusUpdate.getProjectid() === projectId);

    const projectRoles = Object.entries(roles).filter(([_, role]) => {
        const roleName = role.getRolename();
        return roleName.indexOf(projectId) > -1;
    });
    const projectRoleIds = projectRoles.map(([roleId, _]) => roleId);

    const assignedUsers = users ? Object.values(users).filter((user) => {
        const roleIds = user.getRoleidsList();
        return roleIds.find((roleId) => projectRoleIds.includes(roleId));
    }) : [];

    const { user } = oidc;
    let userIsVerified = false;
    let userIsOwnerOfProject = false;
    if (user) {
        const sub = user.profile.sub;
        const userr = users[sub];
        if (userr) {
            userIsVerified = userr.getVerified();

            const roleIds = userr.getRoleidsList();
            const projectRoles = roleIds.find((roleId) => {
                const role = roles[roleId];
                if (role) {
                    const roleName = role.getRolename().toLowerCase();
                    if (roleName.indexOf(projectId) > -1) {
                        if (roleName.indexOf('owner') > -1) {
                            return true;
                        }
                    }
                }
            });
            if (projectRoles && projectRoles.length > 0) {
                userIsOwnerOfProject = true;
            }
        } else {
            userIsVerified = false;
        }
    }

    return {
        ...ownProps,
        created,
        projectDescription,
        projectName,
        tasks: taskss,
        statusUpdates,
        users: assignedUsers.length,
        userIsVerified,
        userIsOwnerOfProject
    };
}

export default connect(mapStateToProps)(ProjectCard);
