import gravatar from 'gravatar';
import React from 'react';
import { Button, Icon, Image, Label, List, Popup } from 'semantic-ui-react';
import { Role, User } from '../../proto/usersroles_pb';
import { possibleRoleSuffixLabel } from '../../utils/roleutils';

interface OwnProps {

    projectId: string;
    onRemoveClick: (user: string, roleId: string) => void;
    assignedUsers: { user: User; roleId: string; role: Role; }[];
}

class UserList extends React.Component<OwnProps> {

    public render() {
        const { assignedUsers, onRemoveClick, projectId } = this.props;
        const isRemovable = assignedUsers.length > 1;
        return (
            <List divided={true} verticalAlign="middle">
                {
                    assignedUsers.map((userAndRole) => {

                        const emaill = userAndRole.user.getEmail();
                        const roleId = userAndRole.roleId;

                        const options = {
                            default: 'identicon',
                        };
                        const avatarUrl = gravatar.url(emaill, options);

                        let roleLabel = possibleRoleSuffixLabel(userAndRole.role);
                        if (roleLabel.startsWith(projectId)) {
                            roleLabel = roleLabel.substring(projectId.length, roleLabel.length);
                        }

                        return (
                            <List.Item key={emaill}>
                                <List.Content floated="right">
                                    {this.RemoveButtonOrTooltip(emaill, roleId, isRemovable, onRemoveClick)}
                                </List.Content>
                                <Image avatar={true} src={avatarUrl} />
                                <List.Content>
                                    {emaill}
                                    <Label horizontal={true} color="green" size="tiny">
                                        {roleLabel}
                                    </Label>
                                </List.Content>
                            </List.Item>
                        );
                    })
                }
            </List>
        );
    }

    private RemoveButtonOrTooltip(username: string, roleId: string, isRemovable: boolean, onRemoveClick: (username: string, roleId: string) => void) {
        const removeButton = this.RemoveButton(username, roleId, isRemovable, onRemoveClick);
        if (isRemovable) {
            return removeButton;
        } else {
            return (
                <Popup
                    trigger={removeButton}
                    content="Page is translated"
                    inverted={true}
                    position={'bottom center'}
                    size={'tiny'}
                />
            );
        }
    }

    private RemoveButton(username: string, roleId: string, isRemovable: boolean, onRemoveClick: (username: string, roleId: string) => void) {
        return (
            <Button disabled={!isRemovable} onClick={() => onRemoveClick(username, roleId)}>
                <Icon name="remove" />
                &nbsp;Remove
          </Button>
        );
    }
}

export default UserList;
