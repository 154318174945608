import { grpc } from '@improbable-eng/grpc-web';
import { push } from 'connected-react-router';
import { toast } from 'react-semantic-toasts';
import { createAction } from 'typesafe-actions';
import { UsersRolesChangeEvent, UsersRolesChangeEventRequest } from '../proto/usersroles_pb';
import { UsersRolesService } from '../proto/usersroles_pb_service';
import { StoreState } from '../store';
import { ThunkDispatch, ThunkResult } from '../store/types';
import userManager from '../utils/userManager';
import { GrpcActionPayload, grpcRequest } from './grpc';

const listeningUserRoleChanges = createAction('LISTENING_USER_ROLE_CHANGES', (resolve) => {
    return (stream: grpc.Request) => resolve(stream);
});
const doUnsubscribeUserRoleChanges = createAction('UNSUBSCRIBE_USER_ROLE_CHANGES', (resolve) => {
    return () => resolve();
});
const receiveUserRoleChangeEvent = createAction('RECEIVED_USER_ROLE_CHANGE_EVENT', (resolve) => {
    return (event: UsersRolesChangeEvent) => resolve(event);
});

let timeout = 500;

export const subscribeUserRoleChangeEvents = () => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new UsersRolesChangeEventRequest();
        const grpcAction: GrpcActionPayload<UsersRolesChangeEventRequest, UsersRolesChangeEvent> = {
            onError: (code: grpc.Code, message: string) => {
                if (code === grpc.Code.Unknown || code === grpc.Code.Unavailable) {
                    toast({
                        title: 'Retrying connection..',
                        description: 'Change listening failed: ' + message,
                        time: timeout,
                        icon: 'unlink',
                        type: 'error'
                    });
                    setTimeout(() => {
                        timeout = timeout * 2;
                        dispatch(subscribeUserRoleChangeEvents());
                    }, timeout);

                } else if (code === grpc.Code.Unauthenticated) {
                    // reauthenticate once
                    userManager.removeUser().then(() => {
                        dispatch(push('/'));
                    });
                } else {
                    toast({
                        title: 'Connection lost',
                        description: 'Change listening failed: ' + message,
                        time: 0,
                        icon: 'unlink',
                        type: 'error'
                    });
                }
                console.log('error: ' + code + ' ' + message);
            },
            onMessage: (event: UsersRolesChangeEvent) => {
                timeout = 500;
                return receiveUserRoleChangeEvent(event);
            },
            onStart: (stream) => {
                return listeningUserRoleChanges(stream);
            },
            methodDescriptor: UsersRolesService.UsersRolesChangeEvents,
            request,
            batch: true
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const unsubscribeUserRoleChanges = (): ThunkResult<void> => {
    return (dispatch: ThunkDispatch) => {
        dispatch(doUnsubscribeUserRoleChanges());
    };
};

export default {
    doUnsubscribeUserRoleChanges,
    listeningUserRoleChanges,
    receiveUserRoleChangeEvent,
};
