import React from 'react';
import { Route, Switch } from 'react-router';
import './App.css';
import CallbackPage from './components/Callback';
import Logout from './components/Logout';
import Protected from './components/Portal/Protected';

export default class Application extends React.PureComponent<{}> {

    public render = () => (
        <Switch>
            <Route exact={true} path="/callback" component={CallbackPage} />
            <Route exact={true} path="/logout" component={Logout} />
            <Route exact={false} path="/" component={Protected} />
        </Switch>
    )
}
