import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Button, Confirm, Divider, Dropdown, Form, Header, Icon, Modal } from 'semantic-ui-react';
import { modifyProject } from '../../actions/projects';
import { changeUserRole } from '../../actions/roles';
import { ChangeOperation, Project, Role, User } from '../../proto/usersroles_pb';
import { TaskStore } from '../../reducers/tasks';
import { StoreState } from '../../store';
import { ThunkDispatch } from '../../store/types';
import { possibleRoleSuffixLabel } from '../../utils/roleutils';
import ProjectDescriptionModalContent from './ProjectDescriptionModalContent';
import ProjectNameModalHeader from './ProjectNameModalHeader';
import UserList from './UserList';
import UserSearchInput from './UserSearchInput';

interface RouteProps {
    projectId: string;
}

interface OwnProps extends RouteComponentProps<RouteProps> {
}

interface DispatchProps {
    closeModal: () => void;
    doChangeUserRole: (projectId: string, user: string, role: string, insert: boolean) => void;

    doUpdateTasks: (projectId: string, name: string, description: string, taskIds: string[]) => void;
}

interface StatePropsFromStore {
    unassignedUsers: string[];
    assignedUsers: { user: User; roleId: string; role: Role; }[];

    project?: Project;

    roleOptions: {
        text: string;
        value: string;
    }[];
    taskOptions: {
        text: string;
        value: string;
    }[];

    tasks: TaskStore;
}

interface OwnState {
    name: string;
    description: string;
    role: string;
    users: string[];

    projectTasks: string[] | null;

    showUpdateTasksDialog: boolean;
}

const initialState = {
    description: '',
    name: '',
    role: '',
    users: [],
    projectTasks: null,
    showUpdateTasksDialog: false,
};

type Props = OwnProps & DispatchProps & StatePropsFromStore;

class EditProjectModal extends React.Component<Props, OwnState> {

    private nameInput: React.RefObject<HTMLInputElement>;

    constructor(props: Props) {
        super(props);
        this.nameInput = React.createRef();
        this.state = initialState;
    }
    public render() {

        const { users, role, projectTasks } = this.state;
        const addButtonEnabled = (users.length !== 0) && (role !== '');
        const { unassignedUsers, match, assignedUsers, roleOptions, taskOptions, tasks } = this.props;
        const projectId = match.params.projectId;

        const availableTasks = Object.entries(tasks).map(([taskId, task]) => {
            return {
                text: task.getName(),
                value: taskId
            };
        });

        const taskValues = taskOptions.map((taskOptions) => taskOptions.value);
        const finalValues = projectTasks !== null ? projectTasks : taskValues; // taskValues.concat(projectTasks);

        return (
            <Modal
                open={true}
                size={'tiny'}
                onClose={this.props.closeModal}
                closeIcon={true}
                className={'modal-fix'}
            >
                <Modal.Header>
                    <ProjectNameModalHeader projectId={projectId} />
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <label>Description</label>
                            <ProjectDescriptionModalContent projectId={projectId} />
                        </Form.Field>
                        <Form.Field>
                            <label>Tasks</label>
                            <Dropdown
                                placeholder="Available tasks"
                                fluid={true}
                                selection={true}
                                options={availableTasks}
                                value={finalValues}
                                multiple={true}
                                search={true}
                                onChange={(event, data) => this.handleTaskInputChange(data.value as string[])}
                            />
                        </Form.Field>
                        <Button onClick={() => this.showUpdateTasksDialog(true)} >
                            <Icon name="add" />
                            &nbsp;Update Tasks
                        </Button>
                        <Divider />
                        <Form.Field>
                            <label>Select users to assign</label>
                            <UserSearchInput
                                users={unassignedUsers}
                                onChange={(event, data) => this.handleUserSearchInputChange(data.value as string[])}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Select a role</label>
                            <Dropdown
                                placeholder="Select role"
                                fluid={true}
                                selection={true}
                                options={roleOptions}

                                onChange={(event, data) => this.handleRoleInputChange(data.value as string)}
                            />
                        </Form.Field>
                        <Button disabled={!addButtonEnabled} onClick={() => this.addUsers()} >
                            <Icon name="add" />
                            &nbsp;Add
                        </Button>
                    </Form>
                    <Divider />
                    <Header>Currently assigned users</Header>
                    <UserList assignedUsers={assignedUsers} projectId={projectId} onRemoveClick={(user, roleId) => this.handleRemove(user, roleId)} />
                </Modal.Content>
                <Confirm
                    open={this.state.showUpdateTasksDialog}
                    onCancel={() => this.showUpdateTasksDialog(false)}
                    onConfirm={() => {
                            this.updateTasks();
                            this.showUpdateTasksDialog(false);
                        }
                    }
                />
            </Modal>
        );
    }

    private showUpdateTasksDialog(status: boolean) {
        this.setState({
            ...this.state,
            showUpdateTasksDialog: status
        });
    }

    private handleUserSearchInputChange(users: string[]) {
        this.setState({
            ...this.state,
            users,
        });
    }

    private handleTaskInputChange(tasks: string[]) {
        this.setState({
            ...this.state,
            projectTasks: tasks
        });
    }

    private handleRoleInputChange(role: string) {
        this.setState({
            ...this.state,
            role,
        });
    }

    private addUsers() {
        const { match } = this.props;
        const { users, role } = this.state;
        users.forEach((user) => {
            this.props.doChangeUserRole(match.params.projectId, user, role, true);
        });
        this.setState({
            ...this.state,
            role: '',
            users: []
        });
    }

    private updateTasks() {
        const { project, match } = this.props;
        const { projectTasks } = this.state;
        if (project && projectTasks !== null) {
            this.props.doUpdateTasks(match.params.projectId, project.getName(), project.getDescription(), projectTasks);
            this.setState({
                ...this.state,
                projectTasks: null
            });
        }
    }

    private handleRemove(user: string, roleId: string) {
        const { match } = this.props;
        this.props.doChangeUserRole(match.params.projectId, user, roleId, false);
    }

}

function mapStateToProps(state: StoreState, ownProps: OwnProps): StatePropsFromStore {
    const { projectId } = ownProps.match.params;
    const { roles, projects, users, tasks } = state;
    const project = projects[projectId];
    const projectRoles = Object.entries(roles).filter(([_, role]) => {
        const roleName = role.getRolename();
        return roleName.indexOf(projectId) > -1;
    });
    const projectRoleIds = projectRoles.map(([roleId, _]) => roleId);

    const unassignedUsers = (users) ? Object.values(users).filter((user) => {
        const roleIds = user.getRoleidsList();
        const found = roleIds.find((roleId) => projectRoleIds.includes(roleId));
        return found === undefined;
    }).map((user) => user.getEmail()) : [];

    const assignedUsers = users ? Object.values(users).filter((user) => {
        const roleIds = user.getRoleidsList();
        const found = roleIds.find((roleId) => projectRoleIds.includes(roleId));
        return found;
    }).map((user) => {
        const roleIds = user.getRoleidsList();
        const found = roleIds.find((roleId) => projectRoleIds.includes(roleId));
        const role = roles[found!];
        return {
            user,
            roleId: found!,
            role
        };
    }) : [];

    const roleOptions = projectRoles.map(([roleId, role]) => {
        let roleLabel = possibleRoleSuffixLabel(role);
        if (roleLabel.startsWith(projectId)) {
            roleLabel = roleLabel.substring(projectId.length, roleLabel.length);
        }
        return {
            text: roleLabel,
            value: roleId
        };
    });

    const taskOptions = project ? project.getTaskidsList().map((taskId) => {
        const task = state.tasks[taskId];
        const taskName = task ? task.getName() : '';
        return {
            text: taskName,
            value: taskId
        };
    }) : [];

    return {
        ...ownProps,
        assignedUsers,
        unassignedUsers,
        roleOptions,
        taskOptions,
        project,
        tasks,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        closeModal: () => {
            dispatch(push('/'));
        },
        doChangeUserRole: (projectId: string, userId: string, role: string, insert: boolean) => {
            dispatch(changeUserRole(projectId, userId, role, insert ? ChangeOperation.INSERT : ChangeOperation.DELETE));
        },
        doUpdateTasks: (projectId: string, name: string, description: string, taskIds: string[]) => {
            dispatch(modifyProject(projectId, name, description, taskIds));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProjectModal);
