// reducers.js
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import { reducer as oidcReducer } from 'redux-oidc';
import auth from './auth';
import groups from './groups';
import permissions from './permissions';
import portalApplications from './portalapplications';
import projects from './projects';
import roles from './roles';
import subscriptions from './subscriptions';
import tasks from './tasks';
import taskStatusUpdates from './taskstatusupdates';
import tokens from './tokens';
import users from './users';

export default (history: History) => combineReducers({
    oidcState: auth,
    router: connectRouter(history),
    users,
    roles,
    groups,
    permissions,
    portalApplications,
    projects,
    subscriptions,
    tasks,
    taskStatusUpdates,
    tokens,
    oidc: oidcReducer,
});
