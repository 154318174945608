import { routerMiddleware, RouterState } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { Action, applyMiddleware, createStore } from 'redux';
import { enableBatching } from 'redux-batched-actions';
import { composeWithDevTools } from 'redux-devtools-extension';
import { loadUser, UserState } from 'redux-oidc';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { newApiMiddleware } from '../middleware/apiMiddleware';
import { newGrpcMiddleware } from '../middleware/grpcMiddleware2';
import createRootReducer from '../reducers';
import { OidcState } from '../reducers/auth';
import { GroupStore } from '../reducers/groups';
import { PermissionStore } from '../reducers/permissions';
import { PortalApplicationStore } from '../reducers/portalapplications';
import { ProjectStore } from '../reducers/projects';
import { RoleStore } from '../reducers/roles';
import { SubscriptionStore } from '../reducers/subscriptions';
import { TaskStore } from '../reducers/tasks';
import { TaskStatusUpdatesStore } from '../reducers/taskstatusupdates';
import { TokenStore } from '../reducers/tokens';
import { UserStore } from '../reducers/users';
import userManager from '../utils/userManager';

export interface StoreState {

    roles: RoleStore;
    router: RouterState;
    users: UserStore;

    groups: GroupStore;

    permissions: PermissionStore;

    portalApplications: PortalApplicationStore;

    projects: ProjectStore;

    tasks: TaskStore;

    subscriptions: SubscriptionStore;

    taskStatusUpdates: TaskStatusUpdatesStore;

    tokens: TokenStore;

    oidc: UserState;

    oidcState: OidcState;
}

export const history = createBrowserHistory({ /*basename*/ });

const reducer = createRootReducer(history); // root reducer with router state

export default function configureStore(preloadedState?: StoreState) {
    const store = createStore(
        enableBatching(reducer),
        // preloadedState,
        composeWithDevTools(
            applyMiddleware(
                routerMiddleware(history), // for dispatching history actions
                // ... other middlewares ...
                thunk as ThunkMiddleware<StoreState, Action>,
                newApiMiddleware(),
                newGrpcMiddleware(),
            ),
        ),
    );
    loadUser(store, userManager);

    if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('../reducers', () => {
            store.replaceReducer(reducer);
        });
    }
    return store;
}
