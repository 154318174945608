import { RouterState } from 'connected-react-router';
import { User } from 'oidc-client';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Image, Menu } from 'semantic-ui-react';
import { TokenStore } from '../../reducers/tokens';
import { StoreState } from '../../store';
import { isAdmin } from '../../utils/roleutils';
import UserDropdown from './UserDropdown';

interface StatePropsFromStore {
    router: RouterState;

    user?: User;

    userIsAdmin: boolean;

    tokens: TokenStore;
}

type Props = StatePropsFromStore;

class NavBar extends React.Component<Props> {
    public render() {
        const { router, user, userIsAdmin } = this.props;
        const { pathname } = router.location;
        const adminActive = pathname.indexOf('/admin') > -1;

        const { email } = user ? user.profile : { email: 'loading' };

        const imageStyle = {
            height: 'auto',
            marginRight: '1em',
            width: '22px',
        };

        const adminMenu = userIsAdmin ? (
            adminActive ? (
                <Menu.Item as={Link} to="/" name="Portal" icon="share" />
            ) : (
                <Menu.Item as={Link} to="/admin" name="Admin" icon="wrench" />
            )
        ) : null;
        const userDropdown = <UserDropdown username={email} email={email} />;
        return (
            <Menu fixed="top">
                <Menu.Item header={true}>
                    <Image style={imageStyle} src={'/favicon.ico'} />
                    Portal | Model Broker
                </Menu.Item>

                <Menu.Menu position="right">
                    {adminMenu}
                    {userDropdown}
                </Menu.Menu>
            </Menu>
        );
    }
}

function mapStateToProps(state: StoreState): StatePropsFromStore {
    const { oidc, router, tokens, roles, users } = state;

    const { user } = oidc;
    let userIsAdmin = false;
    if (user) {
        const sub = user.profile.sub;
        const userr = users[sub];
        if (userr) {
            userIsAdmin = isAdmin(userr, roles);
        }
    }

    return {
        router,
        user,
        userIsAdmin,
        tokens
    };
}

export default connect(mapStateToProps)(NavBar);
