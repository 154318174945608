import React from 'react';
import { connect } from 'react-redux';
import { Icon, Label, TextArea } from 'semantic-ui-react';
import { modifyProject } from '../../actions/projects';
import { StoreState } from '../../store';
import { ThunkDispatch } from '../../store/types';

interface OwnProps {
    projectId: string;
}

interface StatePropsFromStore {
    projectDescription: string;
    projectName: string;
    taskIds: string[];
}

interface DispatchProps {
    doChangeProjectDescription: (projectId: string, name: string, newDesription: string, taskIds: string[]) => void;
}

interface OwnState {
    edit: boolean;
    newDescription: string;
}

type Props = OwnProps & DispatchProps & StatePropsFromStore;
class ProjectDescriptionModalContent extends React.PureComponent<Props, OwnState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            edit: false,
            newDescription: '',
        };
    }

    public render() {
        const { projectDescription } = this.props;
        const { edit } = this.state;
        return edit ? (
            <div style={{position: 'relative'}}>
                <TextArea defaultValue={projectDescription} onChange={this.handleChange} />
                <Label
                    size="large"
                    as="a"
                    style={{position: 'absolute', right: 10, top: 10}}
                    onClick={() => this.handleSubmit()}
                >
                    <Icon name="save" />
                    <Label.Detail>Save</Label.Detail>
                </Label>
            </div>
        ) : (
            <div style={{position: 'relative'}}>
                <TextArea disabled={true} defaultValue={projectDescription} />
                <Label
                    size="large"
                    as="a"
                    style={{position: 'absolute', right: 10, top: 10}}
                    onClick={() => this.startEditing()}
                >
                    <Icon name="edit" />
                    <Label.Detail>Edit</Label.Detail>

                </Label>
            </div>
        );
    }

    private handleSubmit = () => {
        const { projectId, projectName, taskIds, doChangeProjectDescription } = this.props;
        doChangeProjectDescription(projectId, projectName, this.state.newDescription, taskIds);
        this.cancelEditing();
    }

    private handleChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
        this.setState({
            ...this.state,
            newDescription: event.currentTarget.value,
        });
    }

    private startEditing = () => {
        this.setState({
            ...this.state,
            edit: true,
        });
    }

    private cancelEditing = () => {
        this.setState({
            ...this.state,
            edit: false,
        });
    }
}

function mapStateToProps(state: StoreState, ownProps: OwnProps): StatePropsFromStore {
    const { projects } = state;
    const project = projects[ownProps.projectId];
    const projectName = project ? project.getName() : '';
    const projectDescription = project ? project.getDescription() : '';
    const taskIds = project ? project.getTaskidsList() : [];
    return {
        ...ownProps,
        projectDescription,
        projectName,
        taskIds,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        doChangeProjectDescription: (projectId: string, name: string, newDescription: string, taskIds: string[]) => {
            dispatch(modifyProject(projectId, name, newDescription, taskIds));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDescriptionModalContent);
