import { Project, TargetCollection, UsersRolesChangeEvent } from '../proto/usersroles_pb';
import { ChangeEventStore } from '../store/types';
import composable from './composable';

export interface ProjectStore extends ChangeEventStore<Project> {}

export default composable<Project>(
    TargetCollection.PROJECTS,
    UsersRolesChangeEvent.prototype.getProject,
    (changeEvent) => {
        return changeEvent.getContentid();
    }
);
