import { User } from 'oidc-client';
import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { Container, Grid, Message, Transition } from 'semantic-ui-react';
import { ProjectStore } from '../../reducers/projects';
import { StoreState } from '../../store';
import { ThunkDispatch } from '../../store/types';
import ProjectCard from '../ProjectCard';
import ExportProjectModal from '../ProjectCard/ExportProjectModal';
import CreateProjectCard from './CreateProjectCard';
import CreateProjectModal from './CreateProjectModal';
import EditProjectModal from './EditProjectModal';
// import EditProjectModal from './EditProjectModal';
import ImportProjectModal from './ImportProjectModal';
import RemoveProjectModal from './RemoveProjectModal';

interface OwnProps {
}

interface StatePropsFromStore {
    projects: ProjectStore;
    user?: User;
    userIsVerified: boolean | null;
}

interface DispatchProps {

}

interface OwnState {
    filter: string;
    sortCriteria: string;
    sortDirection: string;
}

const initialState = {
    filter: '',
    filteredProjects: [],
    sortCriteria: 'created',
    sortDirection: 'desc',
};

type Props = StatePropsFromStore & DispatchProps;

class Portal extends React.Component<Props, OwnState> {
    constructor(props: Props) {
        super(props);
        this.state = initialState;
    }

    public render() {
        const { user, userIsVerified } = this.props;
        const filteredProjects = this.filterProjects();

        const projs = filteredProjects.map((id) => {
            return (
                <Grid.Column key={id}>
                    <ProjectCard projectId={id} />
                </Grid.Column>
            );
        });

        return (
            <React.Fragment>
                <Container text={true} style={{ paddingTop: '65px' }}>

                    {(userIsVerified)
                        ? <CreateProjectCard
                            onInputChanged={this.handleFilterChange}
                            onSortCriteriaChange={this.handleSortChange}
                        />
                        : (userIsVerified == null) ? (
                            <div></div>
                        ) : (
                            <Message warning={true}>
                                <Message.Header>You must be verified before project creation is allowed.</Message.Header>
                                Contact the system administrator to verify your account!
                            </Message>
                        )
                    }
                    <Transition.Group as={Grid} columns={1}>
                        {projs}
                    </Transition.Group>
                    <Route path="/create" component={CreateProjectModal} />
                    <Route path="/edit/:projectId" component={EditProjectModal} />
                    <Route path="/export/:projectId" component={ExportProjectModal} />
                    <Route path="/import" component={ImportProjectModal} />
                </Container>

                <RemoveProjectModal />
            </React.Fragment>
        );
    }

    private handleFilterChange = (filter: string) => {
        this.setState({
            ...this.state,
            filter
        });
    }

    private handleSortChange = (sortCriteria: string, sortDirection: string) => {
        this.setState({
            ...this.state,
            sortCriteria,
            sortDirection
        });
    }

    private setFilterCriteria(filter: string, sortCriteria: string, sortDirection: string) {
        this.setState({
            ...this.state,
            filter,
            sortCriteria,
            sortDirection
        });
    }

    private filterProjects(): string[] {
        const { projects } = this.props;
        const { filter, sortCriteria, sortDirection } = this.state;
        const filteredProjects = filter.length > 0 ? Object.entries(projects).filter(([id, project]) => {
            return project.getName().toLowerCase().includes(filter.toLowerCase());
        }) : Object.entries(projects);
        const sortedProjects = filteredProjects.sort(([projectIdA, projectA], [projectIdB, projectB]) => {
            let val = 0;
            if (sortCriteria === 'name') {
                const nameA = projectA!.getName();
                const nameB = projectB!.getName();
                val = nameA.localeCompare(nameB);
            } else if (sortCriteria === 'created') {
                const createdA = projectA!.getCreated()!.toDate().getTime();
                const createdB = projectB!.getCreated()!.toDate().getTime();
                val = createdA < createdB ? -1 : 1;
            }
            return sortDirection === 'desc' ? -val : val;
        });
        return sortedProjects.map(([projectId, project]) => projectId);
    }
}

function mapStateToProps(state: StoreState, ownProps: OwnProps): StatePropsFromStore {
    const { projects, oidc, users } = state;
    const { user } = oidc;

    let userIsVerified = null;
    if (user) {
        const sub = user.profile.sub;
        const userr = users[sub];
        if (userr) {
            userIsVerified = userr.getVerified();
        } else {
            userIsVerified = false;
        }
    }
    return {
        ...ownProps,
        projects,
        user,
        userIsVerified
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Portal);
