import { User } from 'oidc-client';
import React from 'react';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import Login from '../Login';
import Portal from './index';

interface StatePropsFromStore {
    user?: User;
}

type Props = StatePropsFromStore;

export class Protected extends React.PureComponent<Props> {

    public render() {
        const { user } = this.props;
        return user ? <Portal /> : <Login />;
    }
}

function mapStateToProps(state: StoreState): StatePropsFromStore {
    const { user } = state.oidc;
    return {
        user
    };
}

export default connect(mapStateToProps)(Protected);
